@import url(https://fonts.googleapis.com/css?family=Noto+Sans+KR:100,300,400,500,700|Noto+Sans:400,700&display=swap&subset=korean);
@charset "utf-8";


body {
  margin: 0;
  /*
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  */
  font-family: 'Noto Sans KR', 'Malgun Gothic', '���� ����', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
    font-family: 조선100년체;
    src: url(/static/media/ChosunCentennial_ttf.305c3475.ttf);
}
